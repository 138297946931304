
import { defineComponent, ref } from 'vue'
import ContactsOrganizationsTable from '@/components/pages/contacts/organizations/ContactsOrganizationsTable.vue'
import TmEmptyState from '@/components/shared/TmEmptyState.vue'
import TmButton from '@/components/shared/TmButton.vue'
import { getTableData } from '@/services/tableService'
import { formatDate } from '@/services/dateTimeService'
import type { TableHeaders } from '@/definitions/shared/types'
import type { ContactOrganization } from '@/definitions/contacts/organizations/types'
import { useModes } from '@/compositions/modes'

export default defineComponent({
  components: {
    ContactsOrganizationsTable,
    TmButton,
    TmEmptyState,
  },
  setup() {
    const { isEmptyMode } = useModes()
    const selected = ref<ContactOrganization[]>([])
    const tableHeaders = ref<TableHeaders[]>([
      { text: 'Name', value: 'organization' },
      { text: 'Domain', value: 'domains', width: '16%' },
      { text: 'Contacts', value: 'contacts', width: '14%' },
      { text: 'Primary contact', value: 'primary-contact', width: '18%' },
      { text: 'Country', value: 'country', width: 180 },
      { text: 'Organization type', value: 'type', width: 160 },
      { text: 'Last updated', value: 'lastUpdated', format: (val: string) => formatDate(val), width: '12%', hidden: true },
      { text: 'Date created', value: 'dateCreated', format: (val: string) => formatDate(val), width: '12%', hidden: true },
    ])
    const tableItems = ref<ContactOrganization[]>(getTableData('contactsOrganizations'))

    return {
      isEmptyMode,
      selected,
      tableHeaders,
      tableItems,
    }
  },
})
